// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ignoreErrors = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
]

Sentry.init({
  dsn: SENTRY_DSN || 'https://96fe7286b4ab41acbebb0ff4bdadad9f@o356934.ingest.sentry.io/5962255',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.FITPETMALL_ENV,
  beforeBreadcrumb(_breadcrumb, hint) {
    /*
      센트리에서 사용자 동작을 로깅하기전에 호출되는 함수
      상품, Ad 등록시 사용되는 위지윅인 react quill 에서 이미지 파일을 업로드할 경우 url 정보가 파일 데이터로 설정됨 (ex. 'data:image/jpeg;base64,/9j/4...')
      breadcrumb 용량이 크면 sentry 로그 전송이 413 payload too large 에러로 인해 실패하므로 data url 다음과 같이 조절
    */

    const breadcrumb =
      _breadcrumb.category === 'fetch'
        ? {
            ..._breadcrumb,
            data: {
              ..._breadcrumb.data,
              url: _breadcrumb.data.url.startsWith('data:')
                ? _breadcrumb.data.url.substr(0, 100)
                : _breadcrumb.data.url,
            },
          }
        : _breadcrumb
    return breadcrumb
  },
  beforeSend: (event) => {
    if (ignoreErrors.includes(event.exception?.values?.[0].value)) {
      return null
    }
    return event
  },
  attachStacktrace: true,
  sendDefaultPii: true,
  integrations: [
    new ExtraErrorDataIntegration({
      // limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
  ],
  ignoreErrors,
})
